/** Global Components
 * @author Virgilio Vázquez J. <virgilio.vazquez@jbge.com.mx>
 */

import NoContent from './components/NoContent'
import PrettyPrint from './components/PrettyPrint'
import ImageWithFallback from './components/ImageWithFallback.vue'

export default {
  install(Vue) {
    Vue.component('no-content', NoContent)
    Vue.component('pretty-print', PrettyPrint)
    Vue.component('img-safe', ImageWithFallback)
  }
}
