<template>
  <v-menu
    offset-y
    origin="center center"
    :nudge-bottom="10"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn large text v-on="on">
        <v-avatar size="36px">
          <!-- size buttom -->
          <img-safe v-if="avatar" :src="avatar" alt="name" />
          <v-icon v-else x-large class="blue--text"> mdi-account </v-icon>
        </v-avatar>
        <p class="mt-4 text--left">
          <span class="subtitle-1 ml-2">
            {{ firstName }}
          </span>
          <br />
          <span class="text-caption ml-2 primary--text text--darken-4">
            {{ (userGroup && userGroup.name) || "N/G" }}
          </span>
        </p>
      </v-btn>
    </template>
    <v-list class="pa-0">
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img-safe v-if="avatar" :src="avatar" />
            <v-icon v-else x-large class="blue--text"> mdi-account </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ name }}</v-list-item-title>
            <v-list-item-subtitle>{{ username }}</v-list-item-subtitle>
          </v-list-item-content>

          <!--<v-list-item-action>-->
          <!--<v-btn-->
          <!--:class="fav ? 'red&#45;&#45;text' : ''"-->
          <!--icon-->
          <!--@click="fav = !fav"-->
          <!--&gt;-->
          <!--<v-icon>mdi-heart</v-icon>-->
          <!--</v-btn>-->
          <!--</v-list-item-action>-->
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list-item
        v-for="(item, index) in visibleMenuItems"
        :key="index"
        :to="!item.href ? { name: item.name } : null"
        :href="item.href"
        ripple="ripple"
        :disabled="item.disabled"
        :target="item.target"
        rel="noopener"
        @click="item.click"
      >
        <v-list-item-action v-if="item.icon">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import authService from '@/services/auth'
import { sendPendingEmails } from '@/services/sinister'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'AppProfile',
  data() {
    return {
      menuitems: [
        {
          icon: 'mdi-account',
          title: 'Perfil',
          click: () => {
            this.$router.push({ name: 'Profile' }, () => {})
          }
        },
        {
          icon: 'mdi-email-arrow-right',
          href: '#',
          title: 'Correos pendientes',
          showIf: () => (this.$can('send_pending_mail', 'Sinister', 'sinister')),
          click: () => {
            this.sendPendingEmails()
          }
        },
        {
          icon: 'mdi-settings',
          href: '#',
          title: 'Opciones',
          click: () => {
            console.log('this.toggleSettingsPanel()')
            this.toggleSettingsPanel()
          }
        },
        {
          icon: 'mdi-exit-to-app',
          href: '#',
          title: 'Salir',
          click: () => {
            this.logout()
          }
        }
      ]
    }
  },
  computed: {
    ...mapState('account', ['username', 'avatar', 'firstName']),
    ...mapGetters('account', ['name', 'userGroup']),
    visibleMenuItems() {
      const { menuitems } = this
      return menuitems
        .filter(({ showIf }) => !showIf || !!showIf())
    }
  },
  methods: {
    logout() {
      authService.logout().then(() => {
        this.$router.push({
          name: 'Login'
        })
      })
    },
    toggleSettingsPanel() {
      console.log('this.toggleSettingsPanel()111')
      this.$vuetify.goTo(0)
      this.$store.dispatch('SettingsPanelToggle')
    },
    sendPendingEmails() {
      sendPendingEmails()
        .then(r => this.$successMessage({ text: 'Se enviaron los correos pendientes'}))
        .catch(this.$fireErrorResponse)
    }
  }
}
</script>
