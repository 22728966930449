
import 'normalize.css/normalize.css'
import 'vuetify/dist/vuetify.min.css'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import './assets/macroColorsLoader.scss'

import permission from '@/store/modules/permission'
import settings from '@/store/modules/settings'
import store from '@/store'
import { version } from '@/config'

import Vue from 'vue'

Vue.prototype.$appName = process.env.NODE_ENV === 'development' ? 'Kraken 7' : 'Aurora 7'
Vue.prototype.$appVersion = version

export default {
  install (Vue) {
    store.registerModule('settings', settings)
    store.registerModule('permission', permission)
  }
}
