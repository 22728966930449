<script>

import Placeholder from "@/assets/images/image_placeholder_squared.jpg"

export default {

  name: 'ImageWithFallback',

  functional: true,

  props: {
    fallback: {
      type: String,
      default: Placeholder,
      required: false
    },
  },

  render(createElement, context) {
    const { props, listeners } = context
    const { attrs } = context.data
    return createElement('img', {
      attrs,
      on: {
        ...listeners,
        error(el,) {
          el.srcElement.src = props.fallback
        },
      }
    })
  }
}
</script>