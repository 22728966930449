
import Layout from '@/layout/TheLayout.vue'
import { EXTRANET_GROUPS } from './utils'

const invoiceViewGroups = ['Admin', 'GeOp', 'Finanzas', 'ACo', 'EsAt', 'CoDi', 'KAM']

export default [
  {
    path: '/',
    name: 'Facturación',
    component: Layout,
    meta: {
      showInSidebar: true,
      icon: 'mdi-receipt',
      ifInGroup: invoiceViewGroups,
      excludeGroup: EXTRANET_GROUPS
    },
    children: [
      {
        path: '/facturas/listasParaFacturar',
        name: 'Ready to Bill',
        component: () => import('@/pages/Invoices/ReadyToBill'),
        meta: {
          title: 'Listos para facturar',
          showInSidebar: true,
          ifCan: {
            view: 'Sinister/sinister'
          }
        }
      },
      {
        path: '/facturas/facturacion',
        name: 'Billing',
        component: () => import('@/pages/Invoices/InvoiceLoad'),
        meta: {
          title: 'Facturación',
          showInSidebar: true
        }
      },
      {
        path: '/facturas/facturados',
        name: 'Billed',
        component: () => import('@/pages/Invoices/Billed'),
        meta: {
          title: 'Facturados',
          showInSidebar: true
        }
      },
      {
        path: '/facturas/Cancelaciones',
        name: 'Cancellations',
        component: () => import('@/pages/Invoices/Cancellations/index.vue'),
        meta: {
          title: 'Cancelaciones',
          showInSidebar: true
        }
      },
      {
        path: '/facturas/facturados/:id',
        name: 'Billed Details',
        component: () => import('@/pages/Invoices/Billed/KBilledDetails'),
        meta: {
          showInSidebar: false
        }
      },
      {
        path: '/facturas/listado_contrarecibos',
        name: 'Contrarecibos',
        component: () => import('@/pages/Invoices/AgainstReceipts/index.vue'),
        meta: {
          title: 'Contrarecibos',
          // show if functionality is going to be required
          showInSidebar: true
          // ifCan: {
          //   view: [
          //     'commons/scopemotifs'
          //   ]
          // }
        }
      },
      {
        path: '/facturas/contrarecibos/registro',
        name: 'Against Receipt Register',
        component: () => import('@/pages/Invoices/AgainstReceipts/register.vue'),
        meta: {
          title: 'Registrar contrarecibos',
          // show if functionality is going to be required
          showInSidebar: true
          // ifCan: {
          //   view: [
          //     'commons/scopemotifs'
          //   ]
          // }
        }
      },
      {
        path: '/facturas/contrarecibos/edicion/:slug',
        name: 'Against Receipt Edition',
        props: true,
        component: () => import('@/pages/Invoices/AgainstReceipts/register.vue'),
        meta: {
          title: 'Registrar contrarecibos'
          // show if functionality is going to be required
          // ifCan: {
          //   view: [
          //     'commons/scopemotifs'
          //   ]
          // }
        }
      },
      {
        path: '/facturas/costoindirecto',
        name: 'Direct Cost',
        component: () => import('@/pages/Invoices/IndirectCost/index.vue'),
        meta: {
          title: 'Costo indirecto',
          // show if functionality is going to be required
          showInSidebar: true
          // ifCan: {
          //   view: [
          //     'commons/scopemotifs'
          //   ]
          // }
        }
      },
      {
        path: '/facturas/complementos',
        name: 'Complements',
        component: () => import('@/pages/Invoices/ComplementsLoad/index.vue'),
        meta: {
          title: 'Complementos',
          // show if functionality is going to be required
          showInSidebar: true
          // ifCan: {
          //   view: [
          //     'commons/scopemotifs'
          //   ]
          // }
        }
      }
    ]
  }
]
